app-user-settings {
    cupcake-suggester {
        .c-suggester-component {
            .c-suggester-clear-icon {
                display: none;
            }
        }
        cupcake-suggester-container-tree {
            li.cupcake-suggester-container-tree-item {
                label {
                    cursor: pointer;
                    .c-suggester-item.item-disabled {
                        label {
                            cursor: pointer;
                        }
                    }
                }

                .c-suggester-item.item-disabled {
                    cursor: pointer;
                    color: inherit;
                    label {
                        cursor: pointer;
                        color: inherit;
                    }

                    &:hover {
                        background-color: #f1f3f5;
                        color: inherit;
                    }
                }
            }
        }
    }
}
