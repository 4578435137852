//** Tooltip max width
$tooltip-max-width:           500px;
//** Tooltip text color
$tooltip-color:               #000000;
//** Tooltip background color
$tooltip-bg:                  #ffffff;
$tooltip-opacity:             1;

//** Tooltip arrow width
$tooltip-arrow-width:         5px;
//** Tooltip arrow color
$tooltip-arrow-color:         $tooltip-bg;


.tooltip.tooltipJs {
    position: absolute;
    background: $tooltip-bg;
    color: $tooltip-color;
    max-width: $tooltip-max-width;
    border-radius: 3px;
    box-shadow: 0 0 9px 0 rgba(0,0,0,.2);
    padding: 5px;
    text-align: center;
    z-index: 5;
    font-size: 12px;
    opacity: 1 !important;
}
.tooltip.tooltipJs .tooltip__arrow.tooltipJs {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: $tooltip-arrow-color
}
.tooltip.tooltipJs[x-placement^="top"] {
    margin-bottom: 5px;
}
.tooltip.tooltipJs[x-placement^="top"] .tooltip__arrow.tooltipJs {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}
.tooltip.tooltipJs[x-placement^="bottom"] {
    margin-top: 5px;
}
.tooltip.tooltipJs[x-placement^="bottom"] .tooltip__arrow.tooltipJs {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}
.tooltip.tooltipJs[x-placement^="right"] {
    margin-left: 5px;
}
.tooltip.tooltipJs[x-placement^="right"] .tooltip__arrow.tooltipJs {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}
.tooltip.tooltipJs[x-placement^="left"] {
    margin-right: 5px;
}
.tooltip.tooltipJs[x-placement^="left"] .tooltip__arrow.tooltipJs {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}
